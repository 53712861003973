import { IndexationSourceType, IndexationType } from "@foudroyer/interfaces"
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import {
  ArrowPathIcon,
  EllipsisVerticalIcon,
  FolderArrowDownIcon,
  FolderIcon,
  KeyIcon,
  LinkIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid"
import classNames from "classnames"
import { CogIcon } from "lucide-react"
import React from "react"
import { getFavicon } from "../../../../../utils/getFavicon"
import { FormattedMessage } from "../../../../general/FormattedMessage/FormattedMessage"
import { FoudroyerLink } from "../../../../general/FoudroyerLink/FoudroyerLink"
import {
  SourceBingButton,
  SourceGoogleButton,
  SourceNaverButton,
  SourceYandexButton,
} from "../../../../general/SourceButtons/SourceButtons"
import { Tooltip } from "../../../../UI/Tooltip"
import {
  connector,
  ContainerProps,
} from "./containers/WebsitesListItem.containers"

type Props = ContainerProps

const getIndexationStatus = (stats: Props["stats"]) => {
  const status = {
    indexed: Number(stats[IndexationType.INDEXED] || 0),
    queued: Number(stats[IndexationType.INDEXING] || 0),
    submitted: Number(stats[IndexationType.SUBMITTED] || 0),
    submittedButNotIndexedYet: Number(
      stats[IndexationType["first-check-done-but-not-indexed"]] || 0
    ),
    error: Number(
      (stats[
        IndexationType[
          "indexation/error/google/cloud/api-key/permission-denied"
        ]
      ] || 0) +
        (stats[
          IndexationType["indexation/error/google/inspect/permission-denied"]
        ] || 0)
    ),
    total: Number(stats.total),
  }

  const notIndexed = Object.values({ ...status, total: 0 }).reduce(
    (acc, curr) => acc - curr,
    status.total
  )

  return {
    ...status,
    notIndexed,
  }
}

export const Wrapper: React.FC<Props> = (props) => {
  const inspectionError = Number(
    props.stats[
      IndexationType["indexation/error/google/inspect/permission-denied"]
    ]
  )

  const isBadUrlFromGSC =
    !props.website.search_console_domain?.includes("sc-domain:") &&
    inspectionError > 0

  const status = getIndexationStatus(props.stats)

  return (
    <div className="relative w-full rounded-md border border-slate-100">
      <div className="p-2">
        <div className="relative flex w-full justify-between">
          <div className="flex items-center md:w-1/4">
            <div className="flex items-center">
              <FoudroyerLink
                to={`/dashboard/indexation/${props.website.id}`}
                className="flex group relative w-full cursor-pointer items-center"
              >
                <img src={getFavicon(props.website.id)} className="h-4 w-4" />
                <span className="ml-2 overflow-hidden truncate group-hover:underline group-hover:text-pink-500  text-sm font-medium text-slate-900">
                  {props.website.id}
                </span>
              </FoudroyerLink>

              <button
                className="flex items-center justify-center ml-2"
                onClick={() =>
                  props.onToggleAutoIndexing({ website: props.website })
                }
              >
                {props.website.indexation_auto_activated && (
                  <Tooltip
                    label={
                      <FormattedMessage id="dashboard/auto-index/tooltip/indexation-auto-activated" />
                    }
                    direction={"bottom"}
                    align="center"
                  >
                    <div className="relative flex-shrink-0 h-2 w-2 ">
                      <div className="absolute inset-0 bg-emerald-300 rounded-full animate-ping"></div>
                      <div className="absolute inset-0 bg-emerald-300 rounded-full"></div>
                    </div>
                  </Tooltip>
                )}

                {!props.website.indexation_auto_activated && (
                  <Tooltip
                    label={
                      <FormattedMessage id="dashboard/auto-index/tooltip/indexation-auto-not-activated" />
                    }
                    direction={"bottom"}
                    align="center"
                  >
                    <div className="relative flex-shrink-0 h-2 w-2 ml-2">
                      <div className="absolute inset-0  bg-slate-200 rounded-full"></div>
                    </div>
                  </Tooltip>
                )}
              </button>

              {Number(props.website.sitemaps_length) === 0 && (
                <Tooltip
                  label={"You have to add a sitemap"}
                  direction={"bottom"}
                  align="center"
                  className="justify-center flex items-center ml-2"
                >
                  <button
                    onClick={() =>
                      props.onClickSitemap({ website: props.website })
                    }
                  >
                    <FolderArrowDownIcon className="h-4 w-4 text-red-500" />
                  </button>
                </Tooltip>
              )}

              {isBadUrlFromGSC && (
                <Tooltip
                  label={
                    "Your site is not well configured on Google Search Console. Click to see more."
                  }
                  direction={"bottom"}
                  align="center"
                  className="justify-center flex items-center ml-2"
                >
                  <button
                    onClick={() =>
                      props.onClickSetupOnGoogle({ website: props.website })
                    }
                  >
                    <CogIcon className="h-4 w-4 text-red-500" />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>

          <div className="relative hidden md:flex items-center">
            {props.index === 0 && (
              <div className="absolute -top-4 left-0 right-0 mx-auto flex -translate-y-full transform items-center justify-center whitespace-nowrap  text-xs">
                <FormattedMessage id="dashboard/indexation-bar/label" />
              </div>
            )}
            <Tooltip
              label={
                <div>
                  <div>
                    {status.indexed > 0 && (
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 rounded-full bg-emerald-300"></div>
                        <FormattedMessage
                          id="dashboard/indexation-bar/tooltip/indexed"
                          values={{
                            p: status.indexed,
                          }}
                        />
                      </div>
                    )}

                    {status.submitted > 0 && (
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                        <FormattedMessage
                          id="dashboard/indexation-bar/tooltip/submitted"
                          values={{
                            p: status.submitted,
                          }}
                        />
                      </div>
                    )}

                    {status.submittedButNotIndexedYet > 0 && (
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 rounded-full bg-indigo-300"></div>
                        <FormattedMessage
                          id="dashboard/indexation-bar/tooltip/submitted-but-not-indexed-yet"
                          values={{
                            p: status.submittedButNotIndexedYet,
                          }}
                        />
                      </div>
                    )}

                    {status.notIndexed > 0 && (
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 rounded-full bg-orange-300"></div>
                        <FormattedMessage
                          id="dashboard/indexation-bar/tooltip/not-indexed"
                          values={{
                            p: status.notIndexed,
                          }}
                        />
                      </div>
                    )}

                    {status.error > 0 && (
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 rounded-full bg-red-300"></div>
                        <FormattedMessage
                          id="dashboard/indexation-bar/tooltip/error"
                          values={{
                            p: status.error,
                          }}
                        />
                      </div>
                    )}

                    {status.queued > 0 && (
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 rounded-full bg-slate-200"></div>
                        <FormattedMessage
                          id="dashboard/indexation-bar/tooltip/indexing"
                          values={{
                            p: status.queued,
                          }}
                        />
                      </div>
                    )}
                    <hr className="my-2 border-slate-600" />
                    <div className="flex items-center justify-center">
                      <FormattedMessage
                        id="dashboard/indexation-bar/tooltip/total"
                        values={{
                          p: props.stats.total,
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
              direction={"bottom"}
              align="center"
            >
              <div className="h-10 w-full flex items-center justify-center">
                <div className="flex relative h-2 w-40 rounded bg-slate-100 overflow-hidden items-center">
                  {!props.fetching && props.stats.total > 0 && (
                    <>
                      <div
                        className="shrink-0 h-full bg-emerald-400"
                        style={{
                          width: `${
                            (status.indexed / props.stats.total) * 100
                          }%`,
                        }}
                      ></div>

                      <div
                        className="h-full shrink-0 bg-blue-400"
                        style={{
                          width: `${
                            (status.submitted / props.stats.total) * 100
                          }%`,
                        }}
                      ></div>

                      <div
                        className="h-full shrink-0 bg-indigo-400"
                        style={{
                          width: `${
                            (status.submittedButNotIndexedYet /
                              props.stats.total) *
                            100
                          }%`,
                        }}
                      ></div>
                      <div
                        className="h-full shrink-0 bg-slate-200"
                        style={{
                          width: `${
                            (status.queued / props.stats.total) * 100
                          }%`,
                        }}
                      ></div>

                      <div
                        className="h-full shrink-0 bg-orange-300"
                        style={{
                          width: `${
                            (status.notIndexed / props.stats.total) * 100
                          }%`,
                        }}
                      ></div>

                      <div
                        className="h-full shrink-0 bg-red-300"
                        style={{
                          width: `${(status.error / props.stats.total) * 100}%`,
                        }}
                      ></div>
                    </>
                  )}

                  <div
                    className={classNames(
                      "w-full h-full shrink-0 bg-slate-100",
                      props.fetching && "animate-pulse"
                    )}
                  ></div>
                </div>
              </div>
            </Tooltip>
          </div>

          <div className="relative hidden md:flex  items-center">
            {props.index === 0 && (
              <div className="absolute -top-4 left-0 right-0 mx-auto flex -translate-y-full transform items-center justify-center whitespace-nowrap  text-xs">
                <FormattedMessage id="dashboard/label/search-engines" />
              </div>
            )}
            <div className="flex gap-2 md:flex">
              <Tooltip
                label={
                  <FormattedMessage
                    id={`indexation/filter-bar/search-engine/google/${
                      props.website.indexation_auto_activated_sources?.includes(
                        "google"
                      )
                        ? "deactivate"
                        : "activate"
                    }`}
                  />
                }
                direction={"bottom"}
                align="center"
              >
                <SourceGoogleButton
                  small
                  onClick={() =>
                    props.onToggleSource({
                      website: props.website,
                      source: IndexationSourceType.google,
                    })
                  }
                  isActive={props.website.indexation_auto_activated_sources?.includes(
                    "google"
                  )}
                />
              </Tooltip>
              <Tooltip
                label={
                  <FormattedMessage
                    id={`indexation/filter-bar/search-engine/bing/${
                      props.website.indexation_auto_activated_sources?.includes(
                        "bing"
                      )
                        ? "deactivate"
                        : "activate"
                    }`}
                  />
                }
                direction={"bottom"}
                align="center"
              >
                <SourceBingButton
                  small
                  onClick={() =>
                    props.onToggleSource({
                      website: props.website,
                      source: IndexationSourceType.yandex,
                    })
                  }
                  isActive={props.website.indexation_auto_activated_sources?.includes(
                    "yandex"
                  )}
                />
              </Tooltip>
              <Tooltip
                label={
                  <FormattedMessage
                    id={`indexation/filter-bar/search-engine/yandex/${
                      props.website.indexation_auto_activated_sources?.includes(
                        "yandex"
                      )
                        ? "deactivate"
                        : "activate"
                    }`}
                  />
                }
                direction={"bottom"}
                align="center"
              >
                <SourceYandexButton
                  small
                  onClick={() =>
                    props.onToggleSource({
                      website: props.website,
                      source: IndexationSourceType.bing,
                    })
                  }
                  isActive={props.website.indexation_auto_activated_sources?.includes(
                    "bing"
                  )}
                />
              </Tooltip>

              <Tooltip
                label={
                  <FormattedMessage
                    id={`indexation/filter-bar/search-engine/naver/${
                      props.website.indexation_auto_activated_sources?.includes(
                        "naver"
                      )
                        ? "deactivate"
                        : "activate"
                    }`}
                  />
                }
                direction={"bottom"}
                align="center"
              >
                <SourceNaverButton
                  small
                  onClick={() =>
                    props.onToggleSource({
                      website: props.website,
                      source: IndexationSourceType.naver,
                    })
                  }
                  isActive={props.website.indexation_auto_activated_sources?.includes(
                    "naver"
                  )}
                />
              </Tooltip>
            </div>
          </div>

          <div className="flex relative items-center justify-center gap-1">
            {props.index === 0 && (
              <div
                onClick={props.onClickSearchBar}
                className="absolute -top-4 left-0 right-0 mx-auto flex -translate-y-full transform items-center justify-center whitespace-nowrap  text-xs"
              >
                <button className="rounded-full hover:bg-pink-50 hover:text-pink-500 w-6 h-6 flex items-center justify-center">
                  <MagnifyingGlassIcon className="h-4 w-4" />
                </button>
              </div>
            )}

            <Popover as="div" className="relative inline-block text-left">
              <div>
                <PopoverButton className="inline-flex w-full justify-center transition rounded-full bg-white px-2 py-2 text-sm font-medium text-slate-900 hover:bg-pink-50 hover:text-pink-500 focus:outline-none  ">
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </PopoverButton>
              </div>

              <PopoverPanel
                transition
                className="absolute right-0 z-10 mt-2 w-auto whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <FoudroyerLink
                    to={`/indexation/${props.website.id}`}
                    className={classNames(
                      "flex w-full items-center gap-2 px-4 py-2 text-sm transition text-slate-900  hover:bg-pink-50 hover:text-pink-500"
                    )}
                  >
                    <LinkIcon className="h-4 w-4" aria-hidden="true" />
                    <FormattedMessage id="dashboard/dropdown/see-pages" />
                  </FoudroyerLink>

                  <button
                    onClick={() =>
                      props.onClickKeys({ website: props.website })
                    }
                    className={classNames(
                      "flex w-full items-center gap-2 px-4 py-2 text-sm transition text-slate-900  hover:bg-pink-50 hover:text-pink-500"
                    )}
                  >
                    <KeyIcon className="h-4 w-4" aria-hidden="true" />
                    <FormattedMessage id="dashboard/dropdown/manage-keys" />
                  </button>

                  <button
                    onClick={() =>
                      props.onClickSitemap({ website: props.website })
                    }
                    className={classNames(
                      "flex w-full items-center gap-2 px-4 py-2 text-sm text-slate-900  hover:bg-pink-50 hover:text-pink-500"
                    )}
                  >
                    <FolderIcon className="h-4 w-4" aria-hidden="true" />
                    <FormattedMessage id="dashboard/dropdown/manage-sitemap" />
                  </button>
                  <button
                    onClick={() =>
                      props.onClickUser({ websiteId: props.website.id })
                    }
                    className={classNames(
                      "flex w-full items-center gap-2 px-4 py-2 text-sm text-slate-900  hover:bg-pink-50 hover:text-pink-500"
                    )}
                  >
                    <UserPlusIcon className="h-4 w-4" aria-hidden="true" />
                    <FormattedMessage id="dashboard/dropdown/manage-users" />
                  </button>
                  <button
                    onClick={() =>
                      props.onToggleAutoIndexing({ website: props.website })
                    }
                    className={classNames(
                      "flex w-full items-center gap-2 px-4 py-2 text-sm text-slate-900  hover:bg-pink-50 hover:text-pink-500"
                    )}
                  >
                    <ArrowPathIcon className="h-4 w-4" aria-hidden="true" />
                    <FormattedMessage id="dashboard/dropdown/manage-auto-index" />
                  </button>

                  <div className="my-1 h-px bg-slate-100" />

                  <button
                    onClick={() => props.onDelete(props.website.id)}
                    className={classNames(
                      "flex w-full items-center gap-2 px-4 py-2 text-sm text-red-500  hover:bg-red-50"
                    )}
                  >
                    <TrashIcon className="h-4 w-4" aria-hidden="true" />

                    <FormattedMessage id="dashboard/dropdown/remove" />
                  </button>
                </div>
              </PopoverPanel>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const WebsitesListItem = connector(Container)
