import React, { useEffect, useState } from "react"
import { ItemLoading } from "../../indexation/IndexedTable/components/ItemLoading"
import { DasboardWebsiteFilterInput } from "../DasboardWebsiteFilterInput/DasboardWebsiteFilterInput"
import { WebsitesListItem } from "./components/WebsitesListItem/WebsitesListItem"
import { connector, ContainerProps } from "./containers/WebsitesList.containers"

type Props = ContainerProps

export const Wrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    props.onDisplay()
  }, [])

  const [searchBarVisible, setSearchBarVisible] = useState(false)

  const websites = props.websites.filter((website) =>
    website.id.toLowerCase().includes(props.filter.toLowerCase())
  )

  return (
    <>
      {searchBarVisible && (
        <div className="pb-8">
          <DasboardWebsiteFilterInput />
        </div>
      )}
      <div className="mt-8 flex flex-wrap gap-4">
        {props.fetching &&
          Array.from({ length: 10 }).map((value, index) => (
            <ItemLoading key={index} delay={index * 100} />
          ))}
        {!props.fetching &&
          websites.map((website, index) => (
            <WebsitesListItem
              onClickSearchBar={() => setSearchBarVisible(!searchBarVisible)}
              key={website.id}
              website={website}
              index={index}
            />
          ))}
      </div>
    </>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const WebsitesList = connector(Container)
