import React from "react"
import { howManyWebsitesUserCanHave } from "../../../../utils/howManyWebsitesUserCanHave"
import { FormattedMessage } from "../../../general/FormattedMessage/FormattedMessage"
import { FoudroyerLink } from "../../../general/FoudroyerLink/FoudroyerLink"
import {
  connector,
  ContainerProps,
} from "./containers/dashboard-hero-quota.containers"

type Props = ContainerProps

export const Wrapper: React.FC<Props> = (props) => {
  const maxWebsites = howManyWebsitesUserCanHave(props.plan)

  return (
    <div className="mt-4 max-w-lg  text-xs">
      {props.numberOfWebsites < maxWebsites && (
        <div className=" text-slate-400">
          <FormattedMessage
            id="dashboard/websites/limit/message"
            values={{
              w: props.numberOfWebsites,
              m: maxWebsites,
            }}
          />
        </div>
      )}

      {props.numberOfWebsites >= maxWebsites && (
        <FoudroyerLink
          to="/pricing?why=indexation/add-website"
          className="text-orange-500 underline"
        >
          <FormattedMessage
            id="dashboard/websites/limit/message/need-upgrade"
            values={{
              w: props.numberOfWebsites,
              m: maxWebsites,
            }}
          />
        </FoudroyerLink>
      )}
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const DashboardHeroQuota = connector(Container)
