import React from "react"
import { FormattedMessage } from "../../general/FormattedMessage/FormattedMessage"
import { ButtonPrimary } from "../../UI/Button/Button"
import { DashboardHeroQuota } from "./components/dashboard-hero-quota"
import {
  connector,
  ContainerProps,
} from "./containers/DashboardHero.containers"

type Props = ContainerProps

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center pb-12 pt-6 text-center">
        <p className="text-center  text-lg font-medium text-pink-400">
          <FormattedMessage id="dashboard/hero/label" />
        </p>
        <h1 className="mx-auto flex flex-col  text-4xl font-semibold leading-tight tracking-tight text-slate-900 ">
          <FormattedMessage id="dashboard/hero/title" />
        </h1>
        <p className="mx-auto mt-2 max-w-lg text-lg leading-normal tracking-tight text-slate-500">
          <FormattedMessage id="dashboard/hero/description" />
        </p>

        <div className="mt-4">
          <ButtonPrimary onClick={props.onAddWebsite}>
            <FormattedMessage id="analytics/button/add-website" />
          </ButtonPrimary>

          <DashboardHeroQuota />
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const DashboardHero = connector(Container)
