import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../../../redux/store"

const mapState = (state: RootState) => ({
  numberOfWebsites: state.websites.entities.length,
  plan: state.payments.actualIndexationPlan,
})

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
